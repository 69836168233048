import React from 'react';
import socialmedia_image from '../assets/images/social-media-1795578_1920.jpg';

/**
 * Stitchz.net index image 2
 * @returns {HTMLElement} html of the index page image 2
 */
export default function IndexImage2() {

    return (
        <img src={socialmedia_image} className="img-fluid" alt="Manage Your Content on Your Schedule" />
    );
}
